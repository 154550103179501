import { default as indexNAO12SRGn5Meta } from "/tmp/build_cb61ed8b/pages/connections/index.vue?macro=true";
import { default as indexJeqvXrxFwSMeta } from "/tmp/build_cb61ed8b/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_937p3zxqSZ1UMeta } from "/tmp/build_cb61ed8b/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93JfPLAt0mC4Meta } from "/tmp/build_cb61ed8b/pages/gallery/[file_id].vue?macro=true";
import { default as galleryewElVZPD9wMeta } from "/tmp/build_cb61ed8b/pages/gallery.vue?macro=true";
import { default as indexTkfgBhfeKZMeta } from "/tmp/build_cb61ed8b/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93LRvd5uKPAAMeta } from "/tmp/build_cb61ed8b/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45inkhLoHVz33jMeta } from "/tmp/build_cb61ed8b/pages/log-in.vue?macro=true";
import { default as log_45outXrUJ7iDHz2Meta } from "/tmp/build_cb61ed8b/pages/log-out.vue?macro=true";
import { default as indexQdgHUD5UB7Meta } from "/tmp/build_cb61ed8b/pages/notifications/index.vue?macro=true";
import { default as projectsGMk26aT30xMeta } from "/tmp/build_cb61ed8b/pages/projects.vue?macro=true";
import { default as _91file_id_93CtgK7o7StDMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93y2jvaY0v0hMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexprHYsLHCZPMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedMMJfhBB28YMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93tgpljZmwG0Meta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93ZeY9TYtsHNMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93UuBbJC51YqMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexJT1dK5PflQMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93SBKwlBegh4Meta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_9338S3hpFaxDMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexI66QZt3jEoMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93HCTK2sB1qHMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsQz01V1gAuMMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_933fKzJv5UvSMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93n3uAUzdyDYMeta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexRKPFIjWc41Meta } from "/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomecb5adnKOjAMeta } from "/tmp/build_cb61ed8b/pages/welcome.vue?macro=true";
import { default as component_45stubDUTpv7ja8qMeta } from "/tmp/build_cb61ed8b/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubDUTpv7ja8q } from "/tmp/build_cb61ed8b/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indexNAO12SRGn5Meta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexJeqvXrxFwSMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_cb61ed8b/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryewElVZPD9wMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_cb61ed8b/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexTkfgBhfeKZMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_cb61ed8b/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_cb61ed8b/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_cb61ed8b/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexQdgHUD5UB7Meta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsGMk26aT30xMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexprHYsLHCZPMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedMMJfhBB28YMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexI66QZt3jEoMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsQz01V1gAuMMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexRKPFIjWc41Meta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomecb5adnKOjAMeta || {},
    component: () => import("/tmp/build_cb61ed8b/pages/welcome.vue")
  },
  {
    name: component_45stubDUTpv7ja8qMeta?.name,
    path: "/",
    component: component_45stubDUTpv7ja8q
  },
  {
    name: component_45stubDUTpv7ja8qMeta?.name,
    path: "/library",
    component: component_45stubDUTpv7ja8q
  },
  {
    name: component_45stubDUTpv7ja8qMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubDUTpv7ja8q
  }
]